// .storybook/YourTheme.js
import { create } from '@storybook/theming';

const protocol = location.protocol;
const slashes = protocol.concat("//");
const host = slashes.concat(window.location.host);

export default create({
  base: 'light',

  colorPrimary: '#f4eee6',
  colorSecondary: '#22a73f',

  // UI
  appBg: '#f4eee6',
  appContentBg: '#fff',
  appBorderColor: '#8a8a8a',
  appBorderRadius: 4,

  // Typography
  fontBase: '"LMSans", sans-serif',
  fontCode: 'monospace',

  // Text colors
  textColor: '#000',
  textInverseColor: 'rgba(255,255,255,0.9)',

  // top Toolbar default and active colors
  barTextColor: '#fff',
  barSelectedColor: '#22a73f',
  barBg: '#303030',

  // Form colors
  inputBg: 'white',
  inputBorder: '#cdcdcd',
  inputTextColor: 'black',
  inputBorderRadius: 2,

  brandTitle: 'LM2 Storybook',
  brandUrl: '/',
  brandImage: `${host}/lm2-logo.svg`,
  brandTarget: '_self',
});
